<template>
  <q-layout view="lHr LpR lfr">
    <q-header style="background:linear-gradient(to bottom, #3D8AC4 , #154172)" elevated>
      <q-toolbar>
        <q-btn flat dense round @click="drawerLeft = !drawerLeft" icon="menu" aria-label="Menu" />
        <q-toolbar-title> Embedded Documentation Manager </q-toolbar-title>
        <q-space />
        <div style="padding-right: 20px;">User: {{ role }}</div>

        <q-btn color="secondary" v-if="role === `administrator`" label="Settings" to="/settings" />

        <q-btn color="secondary" label="Logout" @click="logout()" />
      </q-toolbar>
    </q-header>

    <q-drawer side="left" class="text-white" v-model="drawerLeft" show-if-above elevated
      style="background:linear-gradient(to bottom, #3D8AC4 , #154172)">
      <q-list>
        <div class="q-pa-sm">
          <div class="q-gutter-lg row justify-center">
            <q-img src="@/assets/bicom_logo_white.png" width="200px" class="q-gutter-none" />
          </div>
          <div class="q-pa-xs">
            <q-select dark v-model="selecteddb" emit-value map-options :options="databases" option-value="id"
              label="Database Selection" option-label="name" stack-label @update:model-value="fetchdocs(selecteddb);">
            </q-select>
          </div>
          <q-card v-if=" categories.var1 != `` " style="background:transparent" class="no-shadow">
            <div class="full-width no-wrap">
              <q-tabs v-model=" tab " narrow active-color="white" class="" indicator-color="secondary" align="justify"
                narrow-indicator>
                <q-tab
                  @click=" store(`tab`, tab); store(`subtab`, Object.keys(categories[tab])[0]); store(`expitem`, ``); filter.var1 = tab; filter.var2 = ``; filter.var3 = ``; filter.var4 = Object.keys(categories[tab])[0]; subtab = Object.keys(categories[tab])[0] "
                  v-for="(       content, name       ) in        categories       " :key=" content.message " :name=" name ">
                  <div v-for="       n        in        name.split(`_`)       " :key=" n.message ">
                    {{ n }}
                  </div>
                </q-tab>
              </q-tabs>

              <q-tab-panels v-model=" tab " animated style="background:transparent" class=" text-white ">

                <q-tab-panel class="no-padding" v-for="(       content, name       ) in        categories       "
                  :key=" content.message " :name=" name ">
                  <div v-if=" Object.keys(content).length > 1 ">
                    <q-tabs dense inline-label v-model=" subtab "
                      @click=" store(`tab`, tab); store(`subtab`, subtab); store(`expitem`, ``); filter.var1 = tab; filter.var2 = ``; filter.var3 = ``; filter.var4 = subtab; "
                      narrow active-color="white" class="" indicator-color="secondary" align="justify" narrow-indicator>
                      <div v-if=" content.length > 1 "> yes </div>
                      <q-tab class="" v-for="(       content, name       ) in        content       " :key=" content.message "
                        :name=" name " :label=" `${name = (name === 'mt') ? `Master Tenant` : `Tenant`}` " />
                    </q-tabs>
                  </div>
                  <q-tab-panels v-model=" subtab " animated style="background:transparent" class="text-white ">

                    <q-tab-panel class="no-padding" v-for="(       content, name       ) in        content       "
                      :key=" content.message " :name=" name ">
                      <q-scroll-area style="height: 600px;">
                        <q-tab-panel class="rounded-borders no-padding"
                          v-for="(       content, name       ) in        content       " :key=" content.message "
                          :name=" name ">
                          <div class="full-width column no-wrap justify-center items-center content-center">

                            <q-expansion-item color="linear-gradient(to right, red , yellow)" dense
                              class="col-grow self-stretch" group="somegroup" v-model=" expitem[content.name] "
                              :name=" content.name " :label=" content.name " default-closed
                              @update:model-value=" store(`expitem`, expitem); store(`tab`, tab); store(`subtab`, subtab); "
                              @click=" filter.var1 = tab; filter.var2 = content.var2; filter.var3 = ``; filter.var4 = subtab; ">
                              <div class="full-width column no-wrap justify-center items-center content-center">
                                <q-btn class="col-grow self-stretch text-dark" align="left" dens unelevated rounded: false
                                  v-for="       item        in        content.Filters       " :key=" item.name "
                                  @click=" filter.var1 = tab; filter.var2 = content.var2; filter.var3 = item.var3; filter.var4 = subtab; ">
                                  {{ item.name }}
                                </q-btn>
                              </div>
                            </q-expansion-item>
                          </div>
                        </q-tab-panel>
                      </q-scroll-area>
                    </q-tab-panel>
                  </q-tab-panels>
                </q-tab-panel>

              </q-tab-panels>
            </div>

          </q-card>
          <div class="q-pb-md full-width column no-wrap justify-center items-center content-center" v-if=" filteron ">
            <q-btn style="margin-bottom: 5px" color="red" label="Clear Search" @click=" clearfilters() " />
          </div>
        </div>

      </q-list>
    </q-drawer>

    <q-drawer :width=" 800 " side="right" v-model=" drawerRight " elevated bordered>
      <q-card v-if=" selecteddb && !docid.split('_')[1] " style="background:transparent" class="no-shadow">
        <Tooltip @refreshlist=" fetchdocs(selecteddb); " @tooltiphide=" tooltiphide " :key=" componentKey " />
      </q-card>
      <q-card v-if=" selecteddb && docid.split('_')[1] " style="background:transparent" class="no-shadow">
        <TooltipDraft @refreshlist=" fetchdocs(selecteddb); " @tooltiphide=" tooltiphide " :key=" componentKey " />
      </q-card>
    </q-drawer>
    <q-page-container style="background:white">
      <div class="q-pa-md no-padding">
        <q-table :rows=" tiplist " :columns=" columns " row-key="id" :filter=" filter " :filter-method=" filterData "
          :pagination=" pagination " no-results-label="No documents available.">
          <template v-slot:top-row>
            <q-tr>
              <q-td>
                <q-input outlined v-model=" filter.name " label="Search Name" dense></q-input>
              </q-td>
              <q-td>
                <q-input outlined v-model=" filter.ver " label="Search Version" dense></q-input>
              </q-td>
              <q-td>
                <q-input outlined v-model=" filter.t_lang " label="Search Language" dense></q-input>
              </q-td>
              <q-td> </q-td>
              <q-td style="text-align: right;">
                <q-toggle v-model=" filter.draft " true-value="true" false-value="" label="Drafts Only">
                  <q-toggle v-model=" filter.flagged " true-value="true" false-value="" label="Flagged Only"></q-toggle>
                </q-toggle>
                <q-btn size="15px" color="blue" stack label="Create New" icon="add_circle" no-caps flat dense
                  @click=" addtooltip = !addtooltip " v-if=" checkrole(['administrator', 'publisher']) " />
              </q-td>
            </q-tr>
          </template>
          <template v-slot:body=" props ">
            <q-tr :props=" props "
              :class=" (props.row.id == docid || props.row.id + '_draft' == docid) ? 'bg-grey-13 text-black' : 'bg-white text-black' "
              @click=" opentooltip({ id: props.row.id, draft: props.row.value.draft }, $event) ">
              <q-td key="name" :props=" props ">
                <div class="row text-subtitle2">
                  <div>{{ props.row.value.name }}</div>
                  <div v-if=" props.row.value.flagged " class="text-red">
                    <q-icon name="flag" size="20px" />
                    <q-tooltip>
                      <div class="text-center">Flagged by: {{ props.row.value.notebyuser }}</div>
                      <q-separator color="white" />
                      Note: {{ props.row.value.note }}
                    </q-tooltip>
                  </div>
                  <div v-if=" props.row.value.id === 'homepage' ">
                    <q-chip size="10px" color="primary" text-color="white">Homepage</q-chip>
                  </div>
                </div>
                <div class="row">
                  <q-chip size="10px" color="primary" text-color="white">
                    {{ displaypath(props.row.value) }}
                  </q-chip>
                </div>
              </q-td>
              <q-td key="ver" :props=" props ">
                {{ props.row.value.ver }}
              </q-td>
              <q-td key="t_lang" :props=" props ">
                {{ props.row.value.t_lang }}
              </q-td>
              <q-td class="no-padding" key="timestamp" :props=" props ">
                <div class="column">
                  <div v-if=" props.row.value.timestamp === 'STOCK' ">STOCK</div>
                  <div v-if=" props.row.value.timestamp != 'STOCK' ">
                    <div>
                      Published at {{ date.formatDate(props.row.value.timestamp, 'h:mm A on Do MMM YYYY') }}
                    </div>
                    By {{ props.row.value.byuser }}
                  </div>
                  <div v-if=" props.row.value.draft " class="no-padding text-red text-left">
                    DRAFT EXISTS
                    <q-tooltip>
                      <div class="text-center">Draft Information</div>
                      <q-separator color="white" />
                      <div>
                        Last Update at {{ date.formatDate(props.row.value.drafttimestamp, 'h:mm A on Do MMM YYYY') }}
                      </div>
                      <div class="text-center">Contributors</div>
                      <q-list bordered separator dense>
                        <div v-for="       user        in        props.row.value.draftbyuser.split(',')       " :key=" user ">
                          <q-item dense>
                            {{ user }}
                          </q-item>
                        </div>
                      </q-list>
                    </q-tooltip>
                  </div>
                </div>
              </q-td>
              <q-td style="" key="action">
                <div class="row justify-center">
                  <div class="col">
                  </div>
                  <div class="col-auto">
                    <div class="row">
                      <q-btn v-if=" checkrole(['administrator', 'publisher', 'contributor', 'translator']) " size="12px"
                        color="blue" stack label="Flag" icon="flag" no-caps flat dense @click=" Opennote(props.row) " />
                      <q-btn v-if=" checkrole(['administrator', 'publisher']) " size="12px" color="blue" stack
                        label="Duplicate" icon="content_copy" no-caps flat dense @click=" removedoc() " />
                      <q-btn v-if=" checkrole(['administrator']) " size="12px" color="negative" stack label="Delete"
                        icon="delete" no-caps flat dense @click=" removedoc(props.row) " />
                    </div>

                  </div>
                </div>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </div>
      <q-dialog v-model=" addtooltip ">
        <q-card style="min-width: 550px">
          <q-form @submit=" publishDoc() " class="q-gutter-md">
            <q-card-section>
              <div class="text-h6 row justify-center">Create New Embedded Document</div>
              Creating documents is easier if done through the product UI as it avoids manaully entering the values.
            </q-card-section>
            <q-card-section class="q-pt-none">
              <q-input lazy-rules :rules=" [(val) => (val && val.length > 0)] " color="grey-3" label-color="blue" outlined
                v-model=" createtooltip.name " label="Name"></q-input>
              <q-input lazy-rules :rules=" [(val) => (val && val.length > 0)] " color="grey-3" label-color="blue" outlined
                v-model=" createtooltip.id " label="ID"></q-input>
              <q-input lazy-rules :rules=" [(val) => (val && val.length > 0)] " color="grey-3" label-color="blue" outlined
                v-model=" createtooltip.vars " label="VARs"></q-input>
              <q-input lazy-rules :rules=" [(val) => (val && val > 0)] " color="grey-3" label-color="blue" outlined
                v-model=" createtooltip.ver " label="Version" mask="#.#" fill-mask="0"></q-input>
              <q-separator spaced="12px" />
              <div style="min-height:30px; max-height: 30px;"
                class="row justify-center full-height full-width text-center">
                Description
              </div>
              <QuillEditor v-model:content=" createtooltip.docs.en.description " contentType="html"
                :options=" quillConfig " @textChange=" Clean('en', 'description') " />
              <q-expansion-item header-style="background-color: gainsboro;" style="padding-top: 0%; padding-bottom: 0%;"
                popup icon="note" label="Description Note">
                <QuillEditor v-model:content=" createtooltip.docs.en.description_note " contentType="html"
                  :options=" quillConfig " @textChange=" Clean('en', 'description_note') " />
              </q-expansion-item>
              <q-separator spaced="12px" />
              <div style="min-height:30px; max-height: 30px;"
                class="row justify-center full-height full-width text-center">
                Examples</div>
              <QuillEditor v-model:content=" createtooltip.docs.en.examples " contentType="html" :options=" quillConfig "
                @textChange=" Clean('en', 'examples') " />
              <q-expansion-item header-style="background-color: gainsboro;" style="padding-top: 0%; padding-bottom: 0%;"
                popup icon="note" label="Examples Note">
                <QuillEditor v-model:content=" createtooltip.docs.en.examples_note " contentType="html"
                  :options=" quillConfig " @textChange=" Clean('en', 'examples_note') " />
              </q-expansion-item>
            </q-card-section>
            <q-card-actions align="right" class="text-primary">
              <q-btn flat label="Cancel" v-close-popup />
              <q-btn flat label="Create Document" type="submit" />
            </q-card-actions>
          </q-form>
        </q-card>
      </q-dialog>
      <q-dialog v-model=" opennote ">
        <q-card style="min-width: 550px">
          <q-form @submit=" publishnote() " @delete=" deletenote() " class="q-gutter-md">
            <q-card-section>
              <div class="text-h6 row justify-center">Document Note</div>
            </q-card-section>
            <q-card-section class="q-pt-none">
              <q-input lazy-rules :rules=" [(val) => (val && val.length > 0)] " color="grey-3" label-color="blue" outlined
                v-model=" currentnote.note " label="Note" autogrow></q-input>
              <q-input lazy-rules :rules=" [(val) => (val && val.length > 0)] " color="grey-3" label-color="blue" outlined
                v-model=" currentnote.byuser " label="Created by" disable></q-input>
            </q-card-section>
            <q-card-actions align="right" class="text-primary">
              <q-btn flat label="Delete Note" v-if="currentnote.flagged" @click="deletenote" v-close-popup />
              <q-btn flat :label=" `${currentnote.flagged ? `Update Note` : `Create Note`}` " type="submit" v-close-popup/>
            </q-card-actions>
          </q-form>
        </q-card>
      </q-dialog>
    </q-page-container>
  </q-layout>
</template>
<script>
import { ref, onMounted, onUpdated } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import UIDataService from "@/services/uiapidata";
import { useQuasar, LocalStorage, date } from "quasar";
import Tooltip from "@/views/TooltipQuick";
import TooltipDraft from "@/views/TooltipQuickDraft";
import DOMPurify from "dompurify";
import Store from "@/store/index";
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import 'quill-paste-smart';

export default {
  components: {
    Tooltip,
    TooltipDraft,
    QuillEditor
  },
  async beforeRouteEnter(to, from, next) {
    document.title = `EmDoc - Dashboard`;
    await Store.dispatch('checklogin').then(() => {
      next();
    }).catch(() => {
      LocalStorage.remove('token')
      LocalStorage.remove('role')
      const loginpath = window.location.pathname + location.search;
      next({
        path: '/login',
        query: { from: loginpath }
      })

    })
  },
  setup() {
    onUpdated(() => {
      if (route.query.docid) {
        docid.value = route.query.docid
      }
      window.onpopstate = function () {
        if (route.params.db) {
          selecteddb.value = route.params.db;
          fetchdocs(route.params.db);
        }
        if (route.query.docid) {
          drawerRight.value = true
          store(`drawerRight`, true);
          forceRerender()
        } else {
          drawerRight.value = false
          store(`drawerRight`, false);
        }
      };
    })
    onMounted(() => {
      if (LocalStorage.getItem("filter")) {
        filter.value = LocalStorage.getItem("filter");
      }
      if (!LocalStorage.getItem("expitem")) {
        store(`expitem`, {});
        expitem.value = {};
      }
      fetchdbs();
      if (route.params.db) {
        selecteddb.value = route.params.db;
        fetchdocs(route.params.db);
      }
      if (route.query.docid) {
        docid.value = route.query.docid
      }
      if (route.query.create) {
        addtooltip.value = true
        createtooltip.value = JSON.parse(Buffer.from(route.query.create, 'base64').toString('ascii'))
        createtooltip.value.docs = {
          en: {
            description: "",
            description_note: "",
            examples: "",
            examples_note: ""
          }
        }
      }
      if (!selecteddb.value) {
        clearfilters();
      }
      if (!docid.value) {
        drawerRight.value = false
        store(`drawerRight`, false);
      } else {
        drawerRight.value = true
        store(`drawerRight`, true);
      }
    });
    const columns = [
      {
        name: "name",
        label: "Name",
        align: "left",
        field: (row) => row.value.name,
        sortable: true,
      },
      {
        name: "ver",
        label: "Version",
        align: "left",
        field: (row) => row.value.ver,
        sortable: true,
      },
      {
        name: "t_lang",
        label: "Languages",
        align: "left",
        field: (row) => row.value.t_lang,
        sortable: true,
      },
      {
        name: "timestamp",
        label: "",
        align: "left",
        field: (row) => row.value.timestamp,
        sortable: true,
      },
      { name: "action", field: "action", align: "center" },
    ];
    let filter = ref({
      id: "",
      ver: "",
      t_lang: "",
      name: "",
      var1: "",
      var2: "",
      var3: "",
      var4: "",
      onlyen: "",
      draft: "",
      flagged: ""
    });
    const categories = ref({
      var1: [],
      var2: [],
      var3: [],
      va4: [],
    });
    const notes = ref([]);
    const opennote = ref({})
    const route = useRoute();
    let selecteddb = ref("");
    const databases = ref([]);
    const tiplist = ref([]);
    const draftlist = ref([]);
    let drawerLeft = ref(false);
    let drawerRight = ref(LocalStorage.getItem("drawerRight"));
    const addtooltip = ref(false);
    let createtooltip = ref({
      docs: {
        en: {
          description: "",
          description_note: "",
          examples: "",
          examples_note: ""
        }
      }
    });
    const $q = useQuasar();
    let filteron = ref(LocalStorage.getItem("filteron"));
    let expitem = ref(LocalStorage.getItem("expitem"));
    let tab = ref(LocalStorage.getItem("tab"));
    let subtab = ref(LocalStorage.getItem("subtab"));
    let tooltipdialog = ref(false);
    let currentnote = ref({
      note: ""
    });
    let componentKey = ref(0);
    let docid = ref('');
    let role = ref(LocalStorage.getItem("role"))
    const forceRerender = () => {

      componentKey.value += 1;
    };
    const quillConfig = {
      theme: "snow",
      placeholder: "",
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'list': 'bullet' }],
          [{ 'indent': '-1' }, { 'indent': '+1' }],
          ['clean']],
      },
    }
    // fetch dbs
    async function fetchdbs() {
      const res = await Store.dispatch('getdbs').catch((err) => {
        $q.notify({
          message: `${err}`,
          color: "red",
          position: "bottom-left",
        });
      })
      let dbarr = await res.filter(function (res) {
        return res[0] != "_";
      });
      dbarr.forEach(element => {
        let value = element.replaceAll('_', " ")
        value = value.replaceAll('pbxware mt', "PBXware MT")
        value = value.replaceAll('pbxware cc', "PBXware CC")
        value = value.replaceAll('pbxware biz', "PBXware BIZ")
        value = value.replaceAll('serverware', "SERVERware")
        const arr = value.split(" ");
        for (var i = 0; i < arr.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        databases.value.push({ id: element, name: arr.join(" ") })
      });
    }
    // get categories
    async function fetchcategories() {
      categories.value = await Store.dispatch('getcategories', selecteddb.value).then((data) => { return data.categories }).catch((err) => {
        $q.notify({
          message: `${err}`,
          color: "red",
          position: "bottom-left",
        });
      })
      if (!filteron.value) {
        clearfilters()
      }
    }
    // get notes
    async function fetchnotes() {
      notes.value = await Store.dispatch('getnotes', selecteddb.value).then((data) => { return data.notes }).catch((err) => {
        $q.notify({
          message: `${err}`,
          color: "red",
          position: "bottom-left",
        });
      })
    }
    // get all docs
    async function fetchdocs(selecteddb) {
      if (!route.query.create) {
        if (selecteddb != route.params.db) {
          router.push(`/${selecteddb}`);
        } else {
          router.push(
            `/${selecteddb}${route.query.docid ? "?docid=" + route.query.docid : ""
            }`
          );
        }
      }
      fetchcategories();
      fetchnotes();
      tiplist.value = await Store.dispatch('listdocs', selecteddb).then((data) => { return data.rows }).catch((err) => {
        $q.notify({
          message: `Failed to get Doucments. ${err}`,
          color: "red",
          position: "bottom-left",
        });
      })
      draftlist.value = await Store.dispatch('listdrafts', selecteddb).then((data) => { return data.rows }).catch((err) => {
        $q.notify({
          message: `Failed to get Draft Documents. ${err}`,
          color: "red",
          position: "bottom-left",
        });
      })
      draftlist.value.forEach(element => {
        let obj = tiplist.value.find(x => x.id === element.id.split('_')[0]);
        let index = tiplist.value.indexOf(obj);
        tiplist.value.fill(obj.value.draft = true, index, index++);
        tiplist.value.fill(obj.value.draftbyuser = element.value.byuser, index, index++);
        tiplist.value.fill(obj.value.drafttimestamp = element.value.timestamp, index, index++);
      });
      notes.value.forEach(element => {
        let obj = tiplist.value.find(x => x.id === element.id);
        let index = tiplist.value.indexOf(obj);
        tiplist.value.fill(obj.value.flagged = true, index, index++);
        tiplist.value.fill(obj.value.notebyuser = element.byuser, index, index++);
        tiplist.value.fill(obj.value.note = element.note, index, index++);
      });
    }
    // remove a doc
    function removedoc(row) {
      $q.dialog({
        title: "Confirm",
        message: "Are you sure you want to delete this Document?",
        cancel: true,
        persistent: true,
      }).onOk(() => {
        const data = {
          db: selecteddb.value,
          id: row.id,
          rev: row.value.rev,
        };
        UIDataService.removedoc(data)
          .then(() => {
            $q.notify({
              message: "Document removed.",
              color: "green",
              position: "bottom-left",
            });
            fetchdocs(selecteddb.value);
          })
          .catch((err) => {
            $q.notify({
              message: `Failed to remove Document. Error: ${err.response.status + " - " + err.response.statusText
                }`,
              color: "red",
              position: "bottom-left",
            });
          });
      });
    }
    function filterData(rows, terms) {
      const blankfilter = {
        id: "",
        ver: "",
        t_lang: "",
        name: "",
        var1: "",
        var2: "",
        var3: "",
        var4: "",
        onlyen: "",
        draft: "",
        flagged: ""
      };
      if (JSON.stringify(filter.value) === JSON.stringify(blankfilter)) {
        store(`filteron`, false);
        filteron.value = false;
      } else {
        store(`filteron`, true);
        filteron.value = true;
      }
      store("filter", filter.value);
      for (const term in terms) {
        if (term.startsWith('var')) {
          rows = rows.filter(
            (row) =>
              (row.value[term] + "")
                .toLowerCase().startsWith(terms[term])
          );
        } else {
          rows = rows.filter(
            (row) =>
              (row.value[term] + "")
                .toLowerCase()
                .indexOf(terms[term].toLowerCase()) !== -1
          );
        }
      }
      return rows;
    }
    function clearfilters() {
      if (categories.value) {
        subtab.value = `${Object.keys(categories.value[Object.keys(categories.value)[0]])[0]}`;
        tab.value = `${Object.keys(categories.value)[0]}`;
        store(`expitem`, {});
        store(`subtab`, `${Object.keys(categories.value[Object.keys(categories.value)[0]])[0]}`);
        store(`tab`, `${Object.keys(categories.value)[0]}`);
      }
      filter.value = {
        id: "",
        ver: "",
        t_lang: "",
        name: "",
        var1: "",
        var2: "",
        var3: "",
        var4: "",
        onlyen: "",
        draft: "",
        flagged: ""
      };
      expitem.value = {};
      filteron.value = false;
    }
    async function publishDoc() {
      for (const x in createtooltip.value.docs) {
        for (const y in createtooltip.value.docs[x]) {
          createtooltip.value.docs[x][y] = DOMPurify.sanitize(createtooltip.value.docs[x][y], {
            ALLOWED_TAGS: [
              "strong",
              "em",
              "u",
              "strike",
              "ul",
              "li",
              "br",
              "p"
            ],
          })
          createtooltip.value.docs[x][y] = createtooltip.value.docs[x][y].replace(/(^([ ]*<p><br><\/p>)*)|((<p><br><\/p>)*[ ]*$)/gi, "").trim(" ");
        }
      }
      const _id = Buffer.from(createtooltip.value.id + createtooltip.value.vars.replaceAll("/", "") + createtooltip.value.ver).toString('base64')
      const data = {
        db: route.params.db,
        _id: _id,
        id: createtooltip.value.id,
        vars: createtooltip.value.vars.split("/"),
        ver: createtooltip.value.ver,
        name: createtooltip.value.name,
        docs: createtooltip.value.docs,
        reftooltips: [],
        byuser: LocalStorage.getItem("email")
      };
      await Store.dispatch('putdoc', data).then(() => {
        fetchdocs(route.params.db)
        $q.notify({
          message: `Document Created.`,
          color: "green",
          position: "bottom-left",
        });
        router.push(`/${selecteddb.value}?docid=${_id}`).then(() => {
          forceRerender()
          drawerRight.value = true;
          store(`drawerRight`, true);
        });
      }).catch((err) => {
        $q.notify({
          message: `Error: ${err.response.data.message} Error code: ${err.response.status}`,
          color: "red",
          position: "bottom-left",
        });
      })

    }
    function logout() {
      LocalStorage.remove('token')
      LocalStorage.remove('role')
      router.go(0)
    }
    function store(key, data) {
      LocalStorage.set(key, data);
    }
    function opentooltip(data, event) {
      if (!((event.target.textContent == 'delete') || (event.target.textContent == 'flag'))) {
        if (data.draft) {
          data.id = data.id + `_draft`
        }
        router.push(`/${selecteddb.value}?docid=${data.id}`).then(() => {
          forceRerender()
          drawerRight.value = true;
          store(`drawerRight`, true);
        });
      }
    }
    function tooltiphide() {
      drawerRight.value = false;
      store(`drawerRight`, false);
      router.push(
        `/${selecteddb.value}`)
    }
    function checkrole(data) {
      return data.some(function (item) {
        return (item === role.value);
      });
    }
    function displaypath(row) {
      let data = []
      row.vars.forEach(element => {
        data.push(element.toLowerCase())
      });
      let res = []
      if (Object.keys(categories.value).find(x => x === data[0])) {
        res.push(Object.keys(categories.value).find(x => x === data[0]).replaceAll(`pbx_settings`, `Settings`).replaceAll(`admin_settings`, `Admin Settings`).replaceAll('home', 'Home'))
        if (categories.value[data[0]] && categories.value[data[0]][data[3]] && categories.value[data[0]][data[3]].find(o => o.var2 === data[1])) {
          res.push(categories.value[data[0]][data[3]].find(o => o.var2 === data[1]).name)
          if (categories.value[data[0]] && categories.value[data[0]][data[3]] && categories.value[data[0]][data[3]].find(o => o.var2 === data[1]).Filters.length > 1) {
            if (categories.value[data[0]] && categories.value[data[0]][data[3]] && categories.value[data[0]][data[3]].find(o => o.var2 === data[1]).Filters.find(o => o.var3 === data[2].split(`:`)[0])) {
              res.push(categories.value[data[0]][data[3]].find(o => o.var2 === data[1]).Filters.find(o => o.var3 === data[2].split(`:`)[0]).name)
            }
          }
        }
      }
      return res.join('/')
    }
    function Clean(data, type) {
      if (createtooltip.value.docs[data][type] === `<p><br></p>`) {
        createtooltip.value.docs[data][type] = createtooltip.value.docs[data][type].replace(/(^([ ]*<p><br><\/p>)*)|((<p><br><\/p>)*[ ]*$)/gi, "").trim(" ");
      }
      createtooltip.value.docs[data][type] = DOMPurify.sanitize(createtooltip.value.docs[data][type], {
        ALLOWED_TAGS: [
          "strong",
          "em",
          "u",
          "strike",
          "ul",
          "li",
          "br",
          "p"
        ],
      })
    }
    function Opennote(data) {
      let note = notes.value.find(o => o.id === data.id);
      if (note) {
        currentnote.value = note
        currentnote.value.flagged = true
      } else {
        currentnote.value = {
          id: data.id,
          note: "",
          byuser: LocalStorage.getItem("email"),
          flagged: false
        }
      }
      opennote.value = true
    }
    async function publishnote() {
      if (currentnote.value.flagged) {
        notes.value.find(o => o.id === currentnote.value.id).note = currentnote.value.note;
      } else {
        currentnote.value.flagged = true
        notes.value.push(currentnote.value)
      }
      await Store.dispatch('putnotes', {db: selecteddb.value, notes: notes.value}).then(res => {
                console.error(res)
                fetchdocs(route.params.db)
                $q.notify({
                    message: `Notes Updated.`,
                    color: "green",
                    position: "bottom-left",
                });
            }).catch((err) => {
                console.error(err)
                $q.notify({
                    message: `Error: ${err.response.data.message} Error code: ${err.response.status}`,
                    color: "red",
                    position: "bottom-left",
                });
            })
    }
    async function deletenote() {
      notes.value.splice(notes.value.findIndex(item => item.id === currentnote.value.id), 1)
      await Store.dispatch('putnotes', {db: selecteddb.value, notes: notes.value}).then(res => {
                console.error(res)
                fetchdocs(route.params.db)
                $q.notify({
                    message: `Notes Updated.`,
                    color: "green",
                    position: "bottom-left",
                });
            }).catch((err) => {
                console.error(err)
                $q.notify({
                    message: `Error: ${err.response.data.message} Error code: ${err.response.status}`,
                    color: "red",
                    position: "bottom-left",
                });
            })
    }
    return {
      selecteddb,
      databases,
      categories,
      notes,
      columns,
      tiplist,
      draftlist,
      drawerLeft,
      drawerRight,
      fetchdbs,
      fetchcategories,
      fetchnotes,
      fetchdocs,
      removedoc,
      filter,
      clearfilters,
      filterData,
      filteron,
      addtooltip,
      opennote,
      Opennote,
      currentnote,
      createtooltip,
      publishDoc,
      logout,
      store,
      tab,
      subtab,
      expitem,
      tooltipdialog,
      tooltiphide,
      opentooltip,
      pagination: {
        rowsPerPage: 50,
      },
      componentKey,
      docid,
      role,
      date,
      checkrole,
      displaypath,
      Clean,
      quillConfig,
      publishnote,
      deletenote,
    };
  },
};
</script>
