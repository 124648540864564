import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from './views/Dashboard.vue'
import Login from './views/Login.vue'
import Page404 from './views/Page404.vue'
import Create from './views/Create.vue'
import Settings from './views/Settings.vue'
const routes = [
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/settings/',
    component: Settings,
  },
  {
    path: '/settings/:option',
    component: Settings,
  },
  {
    path: '/create/',
    component: Create,
  },
  {
    path: '/',
    component: Dashboard,
  },
  {
    path: '/:db',
    component: Dashboard,
  },
  {
    path: '/:db/:catchAll(.*)*',
    component: Dashboard,
  },
  {
    path: '/:catchAll(.*)*',
    component: Page404,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
