<template>
  <q-layout view="lHr lpR fFf" style="background: #f5f5f5">
    <q-header
      style="background: linear-gradient(to bottom, #3d8ac4, #154172)"
      elevated
    >
      <q-toolbar>
        <q-btn
          flat
          dense
          round
          @click="drawerLeft = !drawerLeft"
          icon="menu"
          aria-label="Menu"
        />
        <q-toolbar-title> Embedded Documentation Manager </q-toolbar-title>
        <q-space />
        <q-btn color="secondary" label="Logout" @click="logout()" />
      </q-toolbar>
    </q-header>
    <q-drawer
      side="left"
      class="text-white"
      v-model="drawerLeft"
      show-if-above
      elevated
      style="background: linear-gradient(to bottom, #3d8ac4, #154172)"
    >
      <div class="q-pa-sm">
        <div class="q-gutter-lg row justify-center">
          <q-img
            src="@/assets/bicom_logo_white.png"
            width="200px"
            class="q-gutter-none"
          />
        </div>
        <div style="padding-top: 50px" class="row justify-center">
          <div style="width: 100%" class="q-pa-md q-gutter-md">
            <q-btn
              @Click="$router.push(`/`)"
              v-model="active"
              label="Return Home"
            />
            <q-list bordered separator>
              <q-item clickable v-ripple @Click="changeoption(`settings`)">
                <q-item-section avatar>
                  <q-icon name="settings" />
                </q-item-section>
                <q-item-section>General Settings</q-item-section>
              </q-item>

              <q-item clickable v-ripple @Click="changeoption(`users`)">
                <q-item-section avatar>
                  <q-icon name="account_circle" />
                </q-item-section>
                <q-item-section>Users</q-item-section>
              </q-item>

              <q-item clickable v-ripple @Click="changeoption(`logs`)">
                <q-item-section avatar>
                  <q-icon name="info" />
                </q-item-section>
                <q-item-section>Logs</q-item-section>
              </q-item>
            </q-list>
          </div>
        </div>
      </div>
    </q-drawer>
    <q-page-container>
      <component v-bind:is="pageoption" />
    </q-page-container>
  </q-layout>
</template>
<script>
import { ref, onMounted, onUpdated } from "vue";
import { useRoute } from "vue-router";
import { LocalStorage } from "quasar";
import Store from "@/store/index";
import router from "@/router";
import settings from "@/components/settings/generalsettings";
import users from "@/components/settings/users";
import logs from "@/components/settings/logs";
export default {
  components: {
    settings,
    users,
    logs,
  },
  async beforeRouteEnter(to, from, next) {
    document.title = `EmDoc - Settings`;
    await Store.dispatch("checklogin")
      .then(() => {
        next();
      })
      .catch(() => {
        LocalStorage.remove("token");
        LocalStorage.remove("role");
        const loginpath = window.location.pathname + location.search;
        next({
          path: "/login",
          query: { from: loginpath },
        });
      });
  },
  setup() {
    onUpdated(() => {
      window.onpopstate = function () {
        if (route.params.option) {
          pageoption.value = route.params.option;
        } else {
          pageoption.value = "settings";
        }
      };
    });
    onMounted(() => {
      if (route.params.option) {
        pageoption.value = route.params.option;
      } else {
        pageoption.value = "settings";
      }
    });
    const route = useRoute();
    let drawerLeft = ref(false);
    let pageoption = ref(`settings`);
    function logout() {
      LocalStorage.remove("token");
      LocalStorage.remove("role");
      router.go(0);
    }
    function changeoption(data) {
      pageoption.value = data;
      if (data != `settings`) {
        router.push(`/settings/${data}`);
      } else {
        router.push(`/settings`);
      }
    }
    return {
      drawerLeft,
      pageoption,
      changeoption,
      logout,
    };
  },
};
</script>
