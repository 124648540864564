import axios from "axios";
import Vuex from 'vuex'
import VueJwtDecode from 'vue-jwt-decode';
//init store
const Store = new Vuex.Store({
  state: {
    db: process.env.VUE_APP_API,
    user: {},
    currentUser: {},
  },
  mutations: {
  },
  actions: {
    getdbs() {
      return axios.get(this.state.db + `/uiapi/getdbs`).then((res) => {
        if (res.status == 200) {
          return res.data
        }
      });
    },
    getcategories(context, data) {
      return axios.get(this.state.db + `/uiapi/getcategories/${data}`).then((res) => {
        if (res.status == 200) {
          return res.data
        }
      });
    },
    getbranding(context, data) {
      return axios.get(this.state.db + `/uiapi/getbranding/${data}`).then((res) => {
        if (res.status == 200) {
          return res.data
        }
      });
    },
    putbranding(context, data) {
      return axios.put(this.state.db + `/uiapi/putbranding`, data).then((res) => {
          return res
      });
    },
    getnotes(context, data) {
      return axios.get(this.state.db + `/uiapi/getnotes/${data}`).then((res) => {
        if (res.status == 200) {
          return res.data
        }
      });
    },
    putnotes(context, data) {
      return axios.put(this.state.db + `/uiapi/putnotes`, data).then((res) => {
          return res
      });
    },
    listdocs(context, data) {
      return axios.get(this.state.db + `/uiapi/listdocs/${data}`).then((res) => {
        if (res.status == 200) {
          return res.data
        }
      });
    },
    listdrafts(context, data) {
      return axios.get(this.state.db + `/uiapi/listdrafts/${data}`).then((res) => {
        if (res.status == 200) {
          return res.data
        }
      });
    },
    getdoc(context, data) {
      return axios.get(this.state.db + `/uiapi/getdoc/${data.db}/${data.key}`).then((res) => {
        if (res.status == 200) {
          return res.data
        }
      });
    },
    putdoc(context, data) {
      return axios.put(this.state.db + `/uiapi/putdoc`, data).then((res) => {
          return res
      });
    },
    putdraft(context, data) {
      return axios.put(this.state.db + `/uiapi/putdraft`, data).then((res) => {
        if (res.status == 200) {
          return res
        }
      });
    },
    deletedoc(context, data) {
      return axios.delete(this.state.db + `/uiapi/deletedoc/${data.db}/${data.id}/${data.rev}`).then((res) => {
          return res
      });
    },
    deletedraft(context, data) {
      return axios.delete(this.state.db + `/uiapi/deletedraft/${data.db}/${data.id}/${data.rev}`).then((res) => {
          return res
      });
    },
    //User mgmnt
    submitlogin(context, data) {
      return axios.post(this.state.db + `/auth/login`, data).then((res) => {
        if (res.status == 201) {
          return { accces_token: res.data.access_token, role: VueJwtDecode.decode(res.data.access_token).role, email: VueJwtDecode.decode(res.data.access_token).email, status: res.status }
        }
      });
    },
    checklogin() {
      return axios.get(this.state.db + `/auth/login`).then((res) => {
        return { res: res, status: res.status }
      });
    },
    getusers() {
      return axios.get(this.state.db + `/uiapi/getusers`).then((res) => {
        if (res.status == 200) {
          return res.data
        }
      });
    },
    getroles() {
      return axios.get(this.state.db + `/uiapi/getroles`).then((res) => {
        if (res.status == 200) {
          return res.data
        }
      });
    },
    createuser(context, data) {
      return axios.put(this.state.db + `/uiapi/createuser`, data).then((res) => {
          return res
      });
    },
    deleteuser(context, data) {
      return axios.delete(this.state.db + `/uiapi/deleteuser/${data}`).then((res) => {
          return res
      });
    },
    runai(context, data) {
      return axios.get(this.state.db + `/uiapi/runai/${data}`).then((res) => {
        if (res.status == 200) {
          return res.data
        }
      });
    },
    getlogs() {
      return axios.get(this.state.db + `/uiapi/getlogs`).then((res) => {
        if (res.status == 200) {
          return res.data
        }
      });
    },
  },
  getters: {
  },
})

export default Store
