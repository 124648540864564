import axios from 'axios'

const api = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    'Content-type': 'application/json'
  },
  withCredentials: true
})

class UIDataService {
  CheckLogin () {
    return api.get('/_session')
  }

  getDBs () {
    return api.get('/_all_dbs')
  }

  getCategories (dbid) {
    return api.get(`${dbid}/admin_categories`)
  }

  listDocs (dbid) {
    return api.get(`${dbid}/_design/docs/_view/docs`)
  }
  
  listDrafts (dbid) {
    return api.get(`${dbid}/_design/docs/_view/drafts`)
  }

  removedoc (data) {
    return api.delete(`${data.db}/${data.id}?rev=${data.rev}`)
  }

  getDoc (data) {
      return api.get(`${data.db}/${data.id}`)
  }

  publishDoc ({data, db}) {
    return api.put(`${db}/_design/doc/_update/timestamp/${data._id}`, data)
    
  }

  logout () {
    return api.delete('/_session')
  }
}

// export { api }
export default new UIDataService()
