<template>
  <q-layout view="lHh Lpr lFf">
    <q-header elevated>
      <q-toolbar>
        <q-btn
          flat
          dense
          round
          @click="$router.push(`/${route.query.db}`)"
          icon="arrow_back"
        />
        <q-toolbar-title> Tooltip Manager </q-toolbar-title>
        <q-space />
      </q-toolbar>
    </q-header>
    <q-page-container>
      <div class="q-pa-md q-gutter-sm">
        <div class="q-pa-md">
          <div class="row">
            <div class="col">
              <q-list>
                <q-item>Name: {{ tooltipstext.name }}</q-item>
                <q-item>ID: {{ tooltipstext.t_id }}</q-item>
              </q-list>
            </div>
            <div class="col">
              <q-list>
                <q-item>VERSION: {{ tooltipstext.ver }}</q-item>
                <q-item
                  >Tenant level:
                  {{
                    tooltipstext.var4 == "mt" ? "Master Tenant" : "Sub Tenant"
                  }}</q-item
                >
              </q-list>
            </div>
            <div class="col">
              <q-list>
                <q-item>VAR1: {{ tooltipstext.var1 }}</q-item>
                <q-item>VAR2: {{ tooltipstext.var2 }}</q-item>
                <q-item>VAR3: {{ tooltipstext.var3 }}</q-item>
              </q-list>
            </div>
          </div>
        </div>

        <div v-for="item in tooltips" :key="item.message">
          <q-btn
            color="negative"
            icon-right="delete"
            no-caps
            flat
            dense
            @click="removeLanguage(item)"
          />
          Language: {{ item }}
          <q-editor v-model="tooltipstext.tooltips[item]" min-height="5rem" />
        </div>
        <div>
          <q-btn
            label="Add New Language"
            color="primary"
            @click="langprompt = true"
          />
        </div>
        <div class="q-gutter-sm row justify-end">
          <q-btn label="Save Draft" color="primary" @click="saveDraft()" />
          <q-btn label="Publish" color="green" @click="publishDoc()" />
        </div>
      </div>
    </q-page-container>
  </q-layout>
  <q-dialog v-model="langprompt" persistent>
    <q-card style="min-width: 350px">
      <q-card-section>
        <div class="text-h6">Enter Langauge Code</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <q-input dense v-model="langcode" autofocus />
      </q-card-section>

      <q-card-actions align="right" class="text-primary">
        <q-btn flat label="Cancel" v-close-popup />
        <q-btn flat label="Add Language" @click="addLanguage()" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, onMounted } from "vue";
import UIDataService from "@/services/uiapidata";
import { useRoute } from "vue-router";
import { useQuasar, LocalStorage } from "quasar";
import router from "@/router";
import DOMPurify from "dompurify";
import Store from "@/store/index";

export default {
  async beforeRouteEnter(to, from, next) {
    await Store.dispatch("checklogin")
      .then(() => {
        next();
      })
      .catch(() => {
        LocalStorage.remove("token");
        LocalStorage.remove("role");
        const loginpath = window.location.pathname + location.search;
        next({
          path: "/login",
          query: { from: loginpath },
        });
      });
  },
  setup() {
    onMounted(() => {
      init();
    });
    const langprompt = ref(false);
    const route = useRoute();
    let tooltipstext = ref({});
    let tooltips = ref({});
    let langcode = ref("");
    let maindoc = ref({});
    const $q = useQuasar();
    function addLanguage() {
      tooltips.value.push(langcode.value);
      tooltipstext.value.tooltips[langcode.value] = "";
      $q.notify({
        message: "Language added.",
        color: "green",
        position: "bottom-left",
      });
    }
    function init() {
      const name = route.query.name ? route.query.name : "";
      const t_id = route.query.t_id ? route.query.t_id : "";
      const ver = route.query.ver ? route.query.ver : "";
      const var1 = route.query.var1 ? route.query.var1 : "";
      const var2 = route.query.var2 ? route.query.var2 : "";
      const var3 = route.query.var3 ? route.query.var3 : "";
      const var4 = route.query.var4 ? route.query.var4 : "";
      tooltipstext.value = {
        _id: Buffer.from(t_id + var1 + var2 + var3 + ver).toString("base64"),
        name: name,
        t_id: t_id,
        ver: ver,
        var1: var1,
        var2: var2,
        var3: var3,
        var4: var4,
        tooltips: {
          en: `Please enter Text.`,
        },
      };
      tooltips.value = Object.keys(tooltipstext.value.tooltips);
    }
    function removeLanguage(lang) {
      delete tooltipstext.value.tooltips[lang];
      tooltips.value = Object.keys(tooltipstext.value.tooltips);
      $q.notify({
        message: "Language removed.",
        color: "green",
        position: "bottom-left",
      });
    }
    function publishDoc() {
      const data = {
        _id: tooltipstext.value._id,
        t_id: tooltipstext.value.t_id,
        var1: tooltipstext.value.var1,
        var2: tooltipstext.value.var2,
        var3: tooltipstext.value.var3,
        var4: tooltipstext.value.var4,
        ver: tooltipstext.value.ver,
        name: tooltipstext.value.name,
        tooltips: Clean(tooltipstext.value.tooltips),
      };
      const db = route.query.db;
      UIDataService.publishDoc({ data, db })
        .then(() => {
          $q.notify({
            message: "ToolTip Published.",
            color: "green",
            position: "bottom-left",
          });
          router.push(`/${route.query.db}/${tooltipstext.value._id}`);
        })
        .catch((err) => {
          $q.notify({
            message: `Failed to publish ToolTip. Error: ${
              err.response.status + " - " + err.response.statusText
            }`,
            color: "red",
            position: "bottom-left",
          });
        });
    }
    function saveDraft() {
      let data = {};
      data = {
        _id: tooltipstext.value._id + "_draft",
        t_id: tooltipstext.value.t_id,
        var1: tooltipstext.value.var1,
        var2: tooltipstext.value.var2,
        var3: tooltipstext.value.var3,
        var4: tooltipstext.value.var4,
        ver: tooltipstext.value.ver,
        name: tooltipstext.value.name,
        tooltips: tooltipstext.value.tooltips,
      };
      const db = route.query.db;
      UIDataService.publishDoc({ data, db })
        .then(() => {
          $q.notify({
            message: "Draft Saved.",
            color: "green",
            position: "bottom-left",
          });
          router.push(
            `/${route.query.db}/${tooltipstext.value._id + "_draft"}`
          );
        })
        .catch((err) => {
          $q.notify({
            message: `Failed to save draft. Error: ${
              err.response.status + " - " + err.response.statusText
            }`,
            color: "red",
            position: "bottom-left",
          });
        });
    }
    function Clean(data) {
      let clean = {};
      for (const key in data) {
        clean[key] = DOMPurify.sanitize(data[key], {
          ALLOWED_TAGS: [
            "b",
            "i",
            "u",
            "strike",
            "br",
            "font",
            "ul",
            "ol",
            "li",
            "blockquote",
          ],
        });
      }
      return clean;
    }
    return {
      tooltips,
      tooltipstext,
      route,
      langprompt,
      addLanguage,
      langcode,
      removeLanguage,
      publishDoc,
      saveDraft,
      maindoc,
      init,
      Clean,
    };
  },
};
</script>
