<template>
  <q-layout view="hHh lpR fFf" style="background: #F5F5F5;">
    <q-header style="background:linear-gradient(to bottom, #3D8AC4 , #154172)">
      <q-toolbar>
        <q-btn size="18px" stack icon="cancel" no-caps flat dense @click="$emit('tooltiphide')" />
        <q-toolbar-title> Document Editor </q-toolbar-title>
      </q-toolbar>
    </q-header>
    <q-page-container>
      <q-page>
        <div class="q-pa-sm q-gutter-sm column no-padding" style="min-height: inherit;">
          <div class="row" style="flex-grow: 1;">
            <div class="col" style="background: #F5F5F5;">
              <q-card class="full-height" style="border-radius: 0px;">
                <q-scroll-area :visible=false class="full-height" style="background: #F5F5F5;">
                  <div style="background:linear-gradient(to bottom, #3D8AC4 , #154172);" class="text-center text-white">
                    Published</div>
                  <q-tabs style="background: #F5F5F5" v-model="publishedtab" dense active-color="primary"
                    indicator-color="transparent">
                    <div v-for="item in publishedtooltips" :key="item.message">
                      <q-tab
                        :class="(item == publishedtab) ? 'bg-grey-13 text-blue full-width full-height text-subtitle1' : 'bg-white text-black'"
                        content-class="no-padding" style="background: #F5F5F5" :name=item dense active-color="primary"
                        indicator-color="">
                        <div>
                          <div style="padding-right: 10px;" v-if="item == publishedtab">{{ langs.isoLangs[item].name
                          }}</div>
                          <div v-if="item != publishedtab"> {{ item }}</div>
                        </div>
                      </q-tab>
                    </div>
                  </q-tabs>
                  <q-separator />
                  <q-tab-panels style="background: #F5F5F5;" v-model="publishedtab" animated>
                    <q-tab-panel style="background: #F5F5F5;" class="" v-for="(content) in publishedtooltips"
                      :key="content.message" :name="content">
                      <div style="" class="no-padding text-center">
                        <div style="min-height:30px; max-height: 30px;"
                          class="row justify-center full-height full-width text-center">
                        </div>
                        Description
                      </div>
                      <QuillEditor style="background: #FFFFFF" readOnly="true"
                        v-model:content="publishedtooltipstext.docs[content].description" contentType="html"
                        :options="quillConfig" />
                      <q-expansion-item default-opened header-style="background-color: gainsboro;"
                        style="padding-top: 0%; padding-bottom: 0%;" popup icon="note" label="Description Note">
                        <template v-slot:header>
                          <q-item-section avatar>
                            <q-avatar icon="note" />
                          </q-item-section>
                          <q-item-section>
                            <div style="" class="no-padding text-left">
                              <div style="min-height:15px; max-height: 15px;"
                                class="row full-height full-width text-left">
                              </div>
                              Description Note
                            </div>
                          </q-item-section>
                        </template>
                        <QuillEditor style="background: #FFFFFF" readOnly=true
                          v-model:content="publishedtooltipstext.docs[content].description_note" contentType="html"
                          :options="quillConfig" />
                      </q-expansion-item>
                      <q-separator spaced="12px" />
                      <div style="min-height:52px; max-height: 52px;"
                        class="no-padding row justify-center full-height full-width text-center">
                        <div class="no-padding justify-left text-left col">
                        </div>
                        <div class="col">
                          Examples
                        </div>
                        <div class="col">

                        </div>
                      </div>
                      <QuillEditor style="background: #FFFFFF" readOnly=true
                        v-model:content="publishedtooltipstext.docs[content].examples" contentType="html"
                        :options="quillConfig" />
                      <q-expansion-item default-opened header-style="background-color: gainsboro;"
                        style="padding-top: 0%; padding-bottom: 0%;" popup icon="note" label="Examples Note">
                        <template v-slot:header>
                          <q-item-section avatar>
                            <q-avatar icon="note" />
                          </q-item-section>
                          <q-item-section>
                            <div style="" class="no-padding text-left">
                              <div style="min-height:15px; max-height: 15px;"
                                class="row full-height full-width text-left">
                              </div>
                              Examples Note
                            </div>
                          </q-item-section>
                        </template>
                        <QuillEditor style="background: #FFFFFF" readOnly=true
                          v-model:content="publishedtooltipstext.docs[content].examples_note" contentType="html"
                          :options="quillConfig" />
                      </q-expansion-item>
                    </q-tab-panel>
                  </q-tab-panels>
                </q-scroll-area>
              </q-card>
            </div>
            <div class="col">
              <q-card class="full-height" style="border-radius: 0px;">
                <q-scroll-area :visible=false class="full-height" style="background: #F5F5F5;">
                  <div style="background:linear-gradient(to bottom, #3D8AC4 , #154172);" class="text-center text-white">
                    Draft</div>
                  <q-tabs style="background: #F5F5F5!important" v-model="tab" dense active-color="primary"
                    indicator-color="transparent">
                    <div v-for="item in tooltips" :key="item.message">
                      <q-tab
                        :class="(item == tab) ? 'bg-grey-13 text-blue full-width full-height text-subtitle1' : 'bg-white text-black'"
                        content-class="no-padding" style="background: #F5F5F5" :name=item dense active-color="primary">
                        <div>
                          <div style="padding-right: 10px;" v-if="item == tab">{{ langs.isoLangs[item].name
                          }}</div>
                          <div v-if="item != tab"> {{ item }}</div>
                          <div v-if="item == tab">
                            <q-btn outline="" size="9px" style="position: absolute; top: 0%; left:98%;" color="red"
                              icon-right="cancel" no-caps flat dense @click="removeLanguage(item)">
                              <q-tooltip>
                                Remove language
                              </q-tooltip>
                            </q-btn>
                          </div>
                        </div>
                      </q-tab>
                    </div>
                    <q-tab @click="langprompt = true" ripple="false" :name=tab>
                      <q-tooltip>
                        Add Language
                      </q-tooltip>

                      <q-btn size="14px" hint="test" class="no-padding" color="primary" icon-right="add_circle" no-caps
                        flat dense>
                        <q-menu behavior="menu" v-model="langprompt" cover transition-show="scale"
                          transition-hide="scale">
                          <q-select fill-input autofocus filled v-model="langcode" use-input input-debounce="0"
                            label="Languages" :options="langoptions" option-label="name" option-value="code"
                            @filter="langfilter" style="width: 395px;" behavior="menu" @update:model-value="addLanguage">
                          </q-select>
                        </q-menu>
                      </q-btn>
                    </q-tab>
                  </q-tabs>
                  <q-separator />
                  <q-tab-panels style="background: #F5F5F5;" v-model="tab" animated>
                    <q-tab-panel style="background: #F5F5F5;" class="" v-for="(content) in tooltips"
                      :key="content.message" :name="content">
                      <div>
                        <div style="min-height:30px; max-height: 30px;" class="row">
                          <div class="col text-left">
                            <q-btn-dropdown v-if="checkrole(['administrator', 'publisher'])" color="primary"
                              label="ChatGPT" size="13px" dense style="margin:5%">
                              <q-list>
                                <q-item clickable v-close-popup @click="chatgpt(content, 4, 'description')">
                                  <q-item-section>
                                    <q-item-label>Generate Description</q-item-label>
                                  </q-item-section>
                                </q-item>
                                <q-item clickable v-close-popup @click="chatgpt(content, 2)">
                                  <q-item-section>
                                    <q-item-label>Generate Example using
                                      Description</q-item-label>
                                  </q-item-section>
                                </q-item>
                                <q-item clickable v-close-popup @click="chatgpt(content, 1)">
                                  <q-item-section>
                                    <q-item-label>Rewrite Existing Content All
                                      Sections</q-item-label>
                                  </q-item-section>
                                </q-item>
                                <q-item clickable v-close-popup @click="chatgpt(content, 3, 'description')">
                                  <q-item-section>
                                    <q-item-label>Rewrite Existing Content for Description
                                      Section</q-item-label>
                                  </q-item-section>
                                </q-item>
                                <q-item clickable v-close-popup @click="chatgpt(content, 3, 'description_note')">
                                  <q-item-section>
                                    <q-item-label>Rewrite Existing Content for Description
                                      Note
                                      Section</q-item-label>
                                  </q-item-section>
                                </q-item>
                                <q-item clickable v-close-popup @click="chatgpt(content, 3, 'examples')">
                                  <q-item-section>
                                    <q-item-label>Rewrite Existing Content for Examples
                                      Section</q-item-label>
                                  </q-item-section>
                                </q-item>
                                <q-item clickable v-close-popup @click="chatgpt(content, 3, 'examples_note')">
                                  <q-item-section>
                                    <q-item-label>Rewrite Existing Content for Examples Note
                                      Section</q-item-label>
                                  </q-item-section>
                                </q-item>
                                <q-item clickable v-close-popup @click="chatgptlanguageprompt = true">
                                  <q-item-section>
                                    <q-item-label>Translate to New Language</q-item-label>
                                  </q-item-section>
                                </q-item>
                              </q-list>
                            </q-btn-dropdown>
                          </div>
                          <div class="col ">
                            <div class="text-red text-center"
                              v-if="publishedtooltipstext.docs[content] && tooltipstext.docs[content].description.replaceAll(` `, ``) != publishedtooltipstext.docs[content].description.replaceAll(` `, ``)">
                              Changed</div>
                          </div>
                          <div class="col">
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                          </div>
                          <div class="col text-center">
                            Description
                          </div>
                          <div class="col">
                          </div>
                        </div>
                      </div>
                      <QuillEditor style="background: #FFFFFF" v-model:content="tooltipstext.docs[content].description"
                        contentType="html" :options="quillConfig" @textChange="Clean(content, 'description')" />
                      <q-expansion-item default-opened header-style="background-color: gainsboro;"
                        style="padding-top: 0%; padding-bottom: 0%;" popup icon="note" label="Description Note">
                        <template v-slot:header>
                          <q-item-section avatar>
                            <q-avatar icon="note" />
                          </q-item-section>

                          <q-item-section>
                            <div style="" class="no-padding text-left">
                              <div v-if="publishedtooltipstext.docs[content]" style="min-height:15px; max-height: 15px;"
                                class="row full-height full-width text-left">
                                <div class="text-red"
                                  v-if="tooltipstext.docs[content].description_note.replaceAll(` `, ``) != publishedtooltipstext.docs[content].description_note.replaceAll(` `, ``)">
                                  Changed</div>
                              </div>
                              Description Note
                            </div>
                          </q-item-section>
                        </template>
                        <QuillEditor style="background: #FFFFFF"
                          v-model:content="tooltipstext.docs[content].description_note" contentType="html"
                          :options="quillConfig" @textChange="Clean(content, 'description_note')" />
                      </q-expansion-item>
                      <q-separator spaced="12px" />
                      <div style="" class="no-padding text-center">
                        <div v-if="publishedtooltipstext.docs[content]" style="min-height:30px; max-height: 30px;"
                          class="row justify-center full-height full-width text-center">
                          <div class="text-red"
                            v-if="tooltipstext.docs[content].examples.replaceAll(` `, ``) != publishedtooltipstext.docs[content].examples.replaceAll(` `, ``)">
                            Changed</div>
                        </div>
                        Examples
                      </div>
                      <QuillEditor style="background: #FFFFFF" v-model:content="tooltipstext.docs[content].examples"
                        contentType="html" :options="quillConfig" @textChange="Clean(content, 'examples')" />
                      <q-expansion-item default-opened header-style="background-color: gainsboro;"
                        style="padding-top: 0%; padding-bottom: 0%;" popup icon="note">
                        <template v-slot:header>
                          <q-item-section avatar>
                            <q-avatar icon="note" />
                          </q-item-section>
                          <q-item-section>
                            <div style="" class="no-padding text-left">
                              <div v-if="publishedtooltipstext.docs[content]" style="min-height:15px; max-height: 15px;"
                                class="row full-height full-width text-left">
                                <div class="text-red"
                                  v-if="tooltipstext.docs[content].examples_note.replaceAll(` `, ``) != publishedtooltipstext.docs[content].examples_note.replaceAll(` `, ``)">
                                  Changed</div>
                              </div>
                              Examples Note
                            </div>
                          </q-item-section>
                        </template>
                        <QuillEditor style="background: #FFFFFF"
                          v-model:content="tooltipstext.docs[content].examples_note" contentType="html"
                          :options="quillConfig" @textChange="Clean(content, 'examples_note')" />
                      </q-expansion-item>
                    </q-tab-panel>
                  </q-tab-panels>
                </q-scroll-area>
              </q-card>
            </div>
          </div>
          <div class="row">
            <q-icon size="lg" name="info" color="primary">
              <q-tooltip>
                Published Referenced Tooltips
                <q-separator color="white" />
                <q-list v-for="(content) in publishedreftooltips" :key="content" dense bordered padding
                  class="rounded-borders text-center">
                  {{ content.name }}
                </q-list>
              </q-tooltip>
            </q-icon>
            <q-btn v-if="checkrole(['administrator', 'publisher', 'contributor', 'translator'])"
              label="Add Referenced Tooltips" color="blue" @click="clearfilters(); tooltipdialog = true;" />
          </div>
          <div class="row">
            <q-chip v-for="(content) in reftooltips" :key="content" clickable @click="openreftooltip(content.field_id)"
              removable @remove="removeRef(content)">
              {{ content.name }}
            </q-chip>
          </div>
          <div class="q-gutter-sm row">
            <q-btn v-if="checkrole(['administrator', 'publisher', 'contributor', 'translator']) && isdraft"
              label="Delete Draft" color="red" @click="deleteDraft();" />
            <q-btn v-if="checkrole(['administrator', 'publisher', 'contributor', 'translator']) && isdraft"
              label="Save Draft" color="primary" @click="saveDraft();" />
            <q-btn v-if="checkrole(['administrator', 'publisher'])" label="Publish" color="green"
              @click="publishDoc();" />
          </div>
        </div>
        <q-dialog v-model="tooltipdialog" position="bottom">
          <q-card style="max-width: 1500px;width:1000px;height:720px">
            <div class="q-pa-md">
              <div class="row">
                <div class="col-5">
                  <q-scroll-area style="height: 688px;">
                    <div class="full-width no-wrap text-white">
                      <q-tabs v-model="reftab" @click="
                        filter.var1 = reftab;
                      filter.var2 = ``;
                      filter.var3 = ``;
                      filter.var4 = ``;" narrow active-color="white" class="bg-primary" indicator-color="secondary"
                        align="justify" narrow-indicator>
                        <q-tab v-for="(content, name) in categories" :key="content.message" :name="name"
                          :label="`${name.replaceAll(`_`, ` `)}`" />
                      </q-tabs>
                      <q-tab-panels v-model="reftab" animated class="bg-primary text-white ">
                        <q-tab-panel v-for="(content, name) in categories" :key="content.message" :name="name">
                          <q-tabs no-caps dense inline-label v-model="refsubtab" @click="
                            filter.var1 = reftab;
                          filter.var2 = ``;
                          filter.var3 = ``;
                          filter.var4 = refsubtab;" narrow active-color="white" class="bg-primary"
                            indicator-color="secondary" align="justify" narrow-indicator>
                            <q-tab class="rounded-borders no-border-radius" v-for="(content, name) in content"
                              :key="content.message" :name="name"
                              :label="`${name = (name === 'mt') ? `Master Tenant` : `Tenant`}`" />
                          </q-tabs>
                          <q-tab-panels v-model="refsubtab" animated class="bg-primary text-white ">
                            <q-tab-panel v-for="(content, name) in content" :key="content.message" :name="name">
                              <q-tab-panel class="rounded-borders no-border-radius" v-for="(content, name) in content"
                                :key="content.message" :name="name">
                                <div class="full-width column no-wrap justify-center items-center content-center ">
                                  <q-expansion-item class="col-grow self-stretch no-border-radius" group="somegroup"
                                    :name="content.name" :label="content.name" default-closed @click="
                                      filter.var1 = reftab;
                                    filter.var2 = content.var2;
                                    filter.var3 = ``;
                                    filter.var4 = refsubtab;">
                                    <div class="full-width column no-wrap justify-center items-center content-center ">
                                      <q-btn class="col-grow self-stretch no-border-radius" color="secondary" align="left"
                                        dens unelevated rounded: false v-for="item in content.Filters" :key="item.name"
                                        @click="
                                          filter.var1 = reftab;
                                        filter.var2 = content.var2;
                                        filter.var3 = item.var3;
                                        filter.var4 = refsubtab;">
                                        {{ item.name }}
                                      </q-btn>
                                    </div>
                                  </q-expansion-item>
                                </div>
                              </q-tab-panel>
                            </q-tab-panel>
                          </q-tab-panels>
                        </q-tab-panel>
                      </q-tab-panels>
                    </div>
                  </q-scroll-area>
                </div>
                <div class="col">
                  <q-table :rows-per-page-options=[] :pagination="{
                    rowsPerPage: 10,
                  }" title="Add Referenced Documents" :rows="tiplist" :columns="columns" row-key="id" :filter="filter"
                    :filter-method="filterData">
                    <template v-slot:top-row>
                      <q-tr>
                        <q-td>
                          <q-input outlined v-model="filter.name" label="Search Name" dense></q-input>
                        </q-td>
                      </q-tr>
                    </template>
                    <template v-slot:body="props">
                      <q-tr :props="props">
                        <q-td key="name" :props="props">
                          <div class="row text-subtitle2">
                            {{ props.row.value.name }}
                            <div v-if="props.row.value.id === 'homepage'">
                              <q-chip size="10px" color="primary" text-color="white">Homepage</q-chip>
                            </div>
                          </div>
                          <div class="row">
                            <q-chip size="10px" color="primary" text-color="white">
                              {{ displaypath(props.row.value.vars) }}
                            </q-chip>
                          </div>
                        </q-td>
                        <q-td key="action" :props="props">
                          <div v-if="reftooltips.find(x => x.field_path === props.row.value.vars.join('/'))">
                            <q-icon size="25px" name="checkmark" color="green" />
                          </div>
                          <div v-if="!reftooltips.find(x => x.field_path === props.row.value.vars.join('/'))">
                            <q-btn color="blue" icon-right="add_circle" no-caps flat dense
                              @click="addreftooltip(props.row)" />
                          </div>
                        </q-td>
                      </q-tr>
                    </template>
                  </q-table>
                </div>
              </div>
            </div>
          </q-card>
        </q-dialog>
      </q-page>
    </q-page-container>
  </q-layout>
</template>
<script>
import { ref, onMounted, onUpdated } from "vue";
import { useRoute } from "vue-router";
import { useQuasar, LocalStorage } from "quasar";
import DOMPurify from "dompurify";
import Store from "@/store/index";
import router from "@/router";
import isoLangs from "@/services/languagecodes";


import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import 'quill-paste-smart';

export default {
  components: {
    QuillEditor,
  },

  async beforeRouteEnter(to, from, next) {
    await Store.dispatch('checklogin').then(() => {
      next();
    }).catch(() => {
      LocalStorage.remove('token')
      LocalStorage.remove('role')
      const loginpath = window.location.pathname + location.search;
      next({
        path: '/login',
        query: { from: loginpath }
      })

    })
  },
  setup(props, context) {
    onUpdated(() => {
      window.onpopstate = function () {
        if (route.query.docid) {
          getdoc();
          fetchdocs()
        }
      };
    })
    onMounted(() => {
      if (route.query.docid) {
        getdoc();
        fetchdocs()
      }
    });
    let utf8Encode = ref(new TextEncoder())
    let langprompt = ref(false);
    let tooltipdialog = ref(false);
    let isdraft = ref(false);
    const route = useRoute();
    let tooltips = ref({});
    let publishedtooltips = ref({});
    let tooltipstext = ref({
      docs: {
        en: {
          description: "",
          description_note: "",
          examples: "",
          examples_note: ""
        }
      }
    });
    let publishedtooltipstext = ref({
      docs: {
        en: {
          description: "",
          description_note: "",
          examples: "",
          examples_note: ""
        }
      }
    });
    let langcode = ref("");
    let maindoc = ref({});
    let publisheddoc = ref({});
    let apiurl = ref("");
    const $q = useQuasar();
    let langRef = ref(null);
    let reftooltips = ref([]);
    let publishedreftooltips = ref([]);
    const tiplist = ref([]);
    const columns = [
      {
        name: "name",
        align: "left",
        field: (row) => row.value.name,
      },
      { name: "action", field: "action", align: "center" },
    ];
    let filter = ref({
      name: "",
      var1: "",
      var2: "",
      var3: "",
      var4: ""
    });
    const categories = ref({
      var1: [],
      var2: [],
      var3: [],
    });
    let reftab = ref("home");
    let refsubtab = ref("mt");
    let filteron = ref(false);
    let tab = ref('');
    let publishedtab = ref('');
    let entab = ref('en');
    let maintab = ref('')
    let role = ref(LocalStorage.getItem("role"))
    const langs = ref(isoLangs);
    const langoptions = ref(langs.value.isoLangs.array);
    const quillConfig = {
      theme: "snow",
      placeholder: "",
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'list': 'bullet' }],
          [{ 'indent': '-1' }, { 'indent': '+1' }],
          ['clean']],
      },
    }
    async function fetchdocs() {
      tiplist.value = await Store.dispatch('listdocs', route.params.db).then((data) => { return data.rows }).catch((err) => {
        $q.notify({
          message: `Failed to get Tooltips. ${err}`,
          color: "red",
          position: "bottom-left",
        });
      })
      categories.value = await Store.dispatch('getcategories', route.params.db).then((data) => { return data.categories }).catch((err) => {
        $q.notify({
          message: `${err}`,
          color: "red",
          position: "bottom-left",
        });
      })
    }
    function filterData(rows, terms) {
      const blankfilter = {
        var1: "",
        var2: "",
        var3: "",
        var4: "",
      };
      if (JSON.stringify(filter.value) === JSON.stringify(blankfilter)) {
        filteron.value = false;
      } else {
        filteron.value = true;
      }
      for (const term in terms) {
        if (term.startsWith('var')) {
          rows = rows.filter(
            (row) =>
              (row.value[term] + "")
                .toLowerCase().startsWith(terms[term])
          );
        } else {
          rows = rows.filter(
            (row) =>
              (row.value[term] + "")
                .toLowerCase()
                .indexOf(terms[term].toLowerCase()) !== -1
          );
        }
      }
      return rows;
    }
    function clearfilters() {
      filter.value = {
        var1: "",
        var2: "",
        var3: "",
        var4: "",
      };
      refsubtab.value = "mt";
      reftab.value = "home";
      filteron.value = false;
    }
    async function getdoc() {
      let mainres = await Store.dispatch('getdoc', { db: route.params.db, key: route.query.docid }).then((data) => { return data }).catch((err) => {
        $q.notify({
          message: `${err}`,
          color: "red",
          position: "bottom-left",
        });
      })
      let pubres = await Store.dispatch('getdoc', { db: route.params.db, key: route.query.docid.split(`_`)[0] }).then((data) => { return data }).catch((err) => {
        $q.notify({
          message: `${err}`,
          color: "red",
          position: "bottom-left",
        });
      })
      for (const x in mainres.docs) {
        for (const y in mainres.docs[x]) {
          if (mainres.docs[x][y] != '') {
            mainres.docs[x][y] = DOMPurify.sanitize(mainres.docs[x][y], {
              ALLOWED_TAGS: [
                "strong",
                "em",
                "u",
                "strike",
                "ul",
                "li",
                "br",
                "p"
              ],
            })
          }
        }
      }
      for (const x in pubres.docs) {
        for (const y in pubres.docs[x]) {
          if (pubres.docs[x][y] != '') {
            pubres.docs[x][y] = DOMPurify.sanitize(pubres.docs[x][y], {
              ALLOWED_TAGS: [
                "strong",
                "em",
                "u",
                "strike",
                "ul",
                "li",
                "br",
                "p"
              ],
            })
          }
        }
      }
      maindoc.value = mainres
      publisheddoc.value = pubres
      isdraft.value = true;
      apiurl.value = "https://tooltip.bicomsystems.com/api/v1/" +
        "en/" +
        route.params.db +
        "/" +
        maindoc.value.ver +
        "/" +
        maindoc.value.id +
        "/" +
        maindoc.value.vars.toString().replaceAll(",", "/")

      tooltipstext.value = maindoc.value;
      tooltips.value = Object.keys(tooltipstext.value.docs);
      if (tooltipstext.value.reftooltips != null) {
        reftooltips.value = tooltipstext.value.reftooltips;
      }
      tab.value = tooltips.value[0]
      //Published Doc
      publishedtooltipstext.value = publisheddoc.value;
      publishedtooltips.value = Object.keys(publishedtooltipstext.value.docs);
      if (publishedtooltipstext.value.reftooltips != null) {
        publishedreftooltips.value = publishedtooltipstext.value.reftooltips;
      }
      publishedtab.value = publishedtooltips.value[0]
    }
    function addLanguage() {
      if (langcode.value) {
        let data = langcode.value.code
        if (tooltips.value.includes(`${data}`)) {
          langcode.value = "";
          langprompt.value = false;
          $q.notify({
            message: "Language already exists.",
            color: "red",
            position: "bottom-left",
          });
        } else {
          tooltips.value.push(data);
          tooltipstext.value.docs[data] = {
            description: "",
            description_note: "",
            examples: "",
            examples_note: ""
          };
          $q.notify({
            message: "Language added.",
            color: "green",
            position: "bottom-left",
          });
          tab.value = data
          langcode.value = "";
          langprompt.value = false;
        }
      }
    }
    function removeLanguage(data) {
      $q.dialog({
        title: "Confirm",
        message: "Are you sure you want to delete this Language?",
        cancel: true,
        persistent: false,
        focus: 'cancel',
      }).onOk(() => {
        delete tooltipstext.value.docs[data];
        tooltips.value = Object.keys(tooltipstext.value.docs);
        tab.value = Object.keys(tooltipstext.value.docs)[1]
        $q.notify({
          message: "Language removed.",
          color: "green",
          position: "bottom-left",
        });
      });
    }
    function removeRef(data) {
      $q.dialog({
        title: "Confirm",
        message: "Are you sure you want to delete this Referenced Document?",
        cancel: true,
        persistent: false,
      }).onOk(() => {
        reftooltips.value.splice(reftooltips.value.findIndex(item => item.name === data.name), 1)
      });
    }
    async function saveDraft() {
      for (const x in tooltipstext.value.docs) {
        for (const y in tooltipstext.value.docs[x]) {
          tooltipstext.value.docs[x][y] = DOMPurify.sanitize(tooltipstext.value.docs[x][y], {
            ALLOWED_TAGS: [
              "strong",
              "em",
              "u",
              "strike",
              "ol",
              "ul",
              "li",
              "br",
              "p"
            ],
          })
          tooltipstext.value.docs[x][y] = tooltipstext.value.docs[x][y].replace(/(^([ ]*<p><br><\/p>)*)|((<p><br><\/p>)*[ ]*$)/gi, "").trim(" ");
        }
      }
      const data = {
        db: route.params.db,
        _id: maindoc.value._id,
        _rev: maindoc.value._rev,
        id: maindoc.value.id,
        vars: maindoc.value.vars,
        ver: maindoc.value.ver,
        name: maindoc.value.name,
        docs: tooltipstext.value.docs,
        reftooltips: reftooltips.value,
        byuser: maindoc.value.byuser + `,` + LocalStorage.getItem("email")
      };

      await Store.dispatch('putdraft', data).then(() => {
        getdoc();
        fetchdocs()
        context.emit("refreshlist");
        $q.notify({
          message: `Draft Saved.`,
          color: "green",
          position: "bottom-left",
        });
      }).catch((err) => {
        $q.notify({
          message: `Error: ${err.response.data.message} Error code: ${err.response.status}`,
          color: "red",
          position: "bottom-left",
        });
      })

    }
    async function publishDoc() {
      for (const x in tooltipstext.value.docs) {
        for (const y in tooltipstext.value.docs[x]) {
          tooltipstext.value.docs[x][y] = DOMPurify.sanitize(tooltipstext.value.docs[x][y], {
            ALLOWED_TAGS: [
              "strong",
              "em",
              "u",
              "strike",
              "ol",
              "ul",
              "li",
              "br",
              "p"
            ],
          })
          tooltipstext.value.docs[x][y] = tooltipstext.value.docs[x][y].replace(/(^([ ]*<p><br><\/p>)*)|((<p><br><\/p>)*[ ]*$)/gi, "").trim(" ");
        }
      }
      const data = {
        db: route.params.db,
        _id: publisheddoc.value._id,
        id: publisheddoc.value.id,
        _rev: publisheddoc.value._rev,
        vars: publisheddoc.value.vars,
        ver: publisheddoc.value.ver,
        name: publisheddoc.value.name,
        docs: tooltipstext.value.docs,
        reftooltips: reftooltips.value,
        byuser: LocalStorage.getItem("email")
      };
      await Store.dispatch('putdoc', data).then(() => {
        $q.notify({
          message: `Document Published.`,
          color: "green",
          position: "bottom-left",
        });
        const draftdata = {
          db: route.params.db,
          id: maindoc.value._id,
          rev: maindoc.value._rev,
        };
        Store.dispatch('deletedraft', draftdata).then(() => {
          isdraft.value = false
          $q.notify({
            message: `Draft Deleted.`,
            color: "green",
            position: "bottom-left",
          });
          context.emit("refreshlist");
          router.push(`/${route.params.db}?docid=${publisheddoc.value._id}`).then(() => {
            context.emit("refreshlist");
          })
        }).catch((err) => {
          $q.notify({
            message: `${err}`,
            color: "red",
            position: "bottom-left",
          });
        })
      }).catch((err) => {
        $q.notify({
          message: `Error: ${err.response.data.message} Error code: ${err.response.status}`,
          color: "red",
          position: "bottom-left",
        });
      })
    }
    function deleteDraft() {
      const data = {
        db: route.params.db,
        id: maindoc.value._id,
        rev: maindoc.value._rev,
      };
      $q.dialog({
        title: "Confirm",
        message: "Are you sure you want to delete this draft?",
        cancel: true,
        persistent: false,
      }).onOk(() => {
        Store.dispatch('deletedraft', data).then(() => {
          isdraft.value = false
          context.emit("refreshlist");
          router.push(`/${route.params.db}?docid=${publisheddoc.value._id}`).then(() => {
            context.emit("refreshlist");
          })
          $q.notify({
            message: `Draft deleted.`,
            color: "green",
            position: "bottom-left",
          });
        }).catch((err) => {
          $q.notify({
            message: `${err}`,
            color: "red",
            position: "bottom-left",
          });
        })
      });
    }
    function RowClick(row) {
      window.open(`/${route.params.db}/${row.id}`, "_blank");
    }
    function Clean(data, type) {
      if (tooltipstext.value.docs[data][type] === `<p><br></p>`) {
        tooltipstext.value.docs[data][type] = tooltipstext.value.docs[data][type].replace(/(^([ ]*<p><br><\/p>)*)|((<p><br><\/p>)*[ ]*$)/gi, "").trim(" ");
      }
      tooltipstext.value.docs[data][type] = DOMPurify.sanitize(tooltipstext.value.docs[data][type], {
        ALLOWED_TAGS: [
          "strong",
          "em",
          "u",
          "strike",
          "ol",
          "ul",
          "li",
          "br",
          "p"
        ],
      })
    }
    function Capitalize(str) {
      let strVal = '';
      str = str.split(':')
      str = str[0].replaceAll('pbx', "PBX").replaceAll('sip', `SIP`).split(' ');
      for (var chr = 0; chr < str.length; chr++) {
        strVal += str[chr].substring(0, 1).toUpperCase() + str[chr].substring(1, str[chr].length) + ' '
      }
      return strVal.trim()
    }
    async function openreftooltip(data) {
      await router.push(`/${route.params.db}?docid=${data}`)
      getdoc();
      fetchdocs()
    }
    function addreftooltip(data) {
      if (reftooltips.value.find(x => x.field_id === data.id)) {
        $q.notify({
          message: "Referenced Document already exists.",
          color: "green",
          position: "bottom-left",
        });
      } else {
        reftooltips.value.push({
          name: data.value.name,
          display_path: displaypath(data.value.vars),
          field: data.value.id,
          field_path: `${data.value.var1}/${data.value.var2}/${data.value.var3}/${data.value.var4}`,
          field_id: `${data.id}`
        })
      }
    }
    function displaypath(row) {
      let data = []
      row.forEach(element => {
        data.push(element.toLowerCase())
      });
      let res = []
      if (Object.keys(categories.value).find(x => x === data[0])) {
        res.push(Object.keys(categories.value).find(x => x === data[0]).replaceAll(`pbx_settings`, `Settings`).replaceAll(`admin_settings`, `Admin Settings`).replaceAll('home', 'Home'))
        if (categories.value[data[0]] && categories.value[data[0]][data[3]] && categories.value[data[0]][data[3]].find(o => o.var2 === data[1])) {
          res.push(categories.value[data[0]][data[3]].find(o => o.var2 === data[1]).name)
          if (categories.value[data[0]] && categories.value[data[0]][data[3]] && categories.value[data[0]][data[3]].find(o => o.var2 === data[1]).Filters.length > 1) {
            if (categories.value[data[0]] && categories.value[data[0]][data[3]] && categories.value[data[0]][data[3]].find(o => o.var2 === data[1]).Filters.find(o => o.var3 === data[2].split(`:`)[0])) {
              res.push(categories.value[data[0]][data[3]].find(o => o.var2 === data[1]).Filters.find(o => o.var3 === data[2].split(`:`)[0]).name)
            }
          }
        }
      }
      return res.join('/')
    }
    function checkrole(data) {
      return data.some(function (item) {
        return (item === role.value);
      });
    }
    function langfilter(val, update) {
      setTimeout(() => {
        update(
          () => {
            if (val === '') {
              langoptions.value = langs.value.isoLangs.array
            }
            else {
              const needle = val.toLowerCase()
              langoptions.value = langs.value.isoLangs.array.filter(v => v.name.toLowerCase().indexOf(needle) > -1)
            }
          },
          ref => {
            if (val !== '' && ref.options.length > 0) {
              ref.setOptionIndex(-1)
              ref.moveOptionSelection(1, true)
            }
          }
        )
      }, 300)
    }
    async function chatgpt(data, option, section) {
      if (option === 1) {
        for (const x in tooltipstext.value.docs[data]) {
          if (tooltipstext.value.docs[data][x] != ``) {
            let req = `Rewrite following ${x} of an option within PBXware and use language ${data}:` + tooltipstext.value.docs[data][x]
            req = Buffer.from(req).toString('base64')
            tooltipstext.value.docs[data][x] = await Store.dispatch('runai', req).then((data) => {
              return data
            }).catch(() => { })
          }
        }
      }
      if (option === 2) {
        if (tooltipstext.value.docs[data].description != ``) {
          let req = `Give me a example where this would be used in PBXware and use language ${data} in 1 paragraph:` + tooltipstext.value.docs[data].description
          req = Buffer.from(req).toString('base64')
          tooltipstext.value.docs[data].examples = await Store.dispatch('runai', req).then((data) => {
            return data
          }).catch(() => { })
        } else {
          $q.notify({
            message: "Cannot process empty description field.",
            color: "red",
            position: "bottom-left",
          });
        }
      }
      if (option === 3) {
        if (tooltipstext.value.docs[data][section] != ``) {
          let req = `Rewrite following ${section} of an option within PBXware and use language ${data}:` + tooltipstext.value.docs[data][section]
          req = Buffer.from(req).toString('base64')
          tooltipstext.value.docs[data][section] = await Store.dispatch('runai', req).then((data) => {
            return data
          }).catch(() => { })
        } else {
          $q.notify({
            message: "Cannot process empty field.",
            color: "red",
            position: "bottom-left",
          });
        }
      }
      if (option === 4) {
        let var2 = categories.value[tooltipstext.value.vars[0]][tooltipstext.value.vars[3]].find(o => o.var2 === tooltipstext.value.vars[1]).name
        let var3 = categories.value[tooltipstext.value.vars[0]][tooltipstext.value.vars[3]].find(o => o.var2 === tooltipstext.value.vars[1]).Filters.find(o => o.var3 === tooltipstext.value.vars[2].split(`:`)[0]).name
        let req = `Short paragraph description of ${tooltipstext.value.name} option in PBXware section ${var2}/${var3} and use language ${data}`
        req = Buffer.from(req).toString('base64')
        tooltipstext.value.docs[data][section] = await Store.dispatch('runai', req).then((data) => {
          return data
        }).catch(() => { })
      }
    }
    return {
      tooltips,
      publishedtooltips,
      tooltipstext,
      publishedtooltipstext,
      route,
      langprompt,
      langfilter,
      getdoc,
      addLanguage,
      langcode,
      removeLanguage,
      publishDoc,
      deleteDraft,
      saveDraft,
      maindoc,
      publisheddoc,
      isdraft,
      langRef,
      reftooltips,
      publishedreftooltips,
      fetchdocs,
      tiplist,
      tooltipdialog,
      filterData,
      columns,
      filter,
      RowClick,
      Clean,
      removeRef,
      apiurl,
      reftab,
      refsubtab,
      categories,
      filteron,
      clearfilters,
      tab,
      entab,
      Capitalize,
      openreftooltip,
      displaypath,
      addreftooltip,
      role,
      checkrole,
      maintab,
      langs,
      langoptions,
      utf8Encode,
      publishedtab,
      chatgpt,
      quillConfig
    };
  },
};
</script>